import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Box,
} from '@mui/material';
import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    Instagram as InstagramIcon,
    Reddit as RedditIcon,
    CopyAll as ClipboardIcon,
    Share as ShareIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import theme from '../theme';


const ShareButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
`;

const ShareResultsModal = ({ open, onClose, shareId }) => {
    const environment_URL = process.env.REACT_APP_ENVIRONMENT_URL;
    const shareUrl = `${environment_URL}/share-results?shareid=${shareId}`;
    const [copySuccess, setCopySuccess] = useState('');
    const [isMobileShareSupported, setIsMobileShareSupported] = useState(false);

    useEffect(() => {
        setIsMobileShareSupported('share' in navigator);
    }, []);

    const shareLink = async () => {
        if (isMobileShareSupported) {
            try {
                await navigator.share({
                    title: 'Check out my Politicalc quiz results!', // You can customize the title for the share
                    url: shareUrl,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyLink();
        }
    };

    const copyLink = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopySuccess('Link copied!');
        });
    };

    // Customize the share texts for each social media platform
    const shareToTwitter = () => {
        const text = `Check out my @PoliticalcTest quiz results! ${shareUrl}`;
        window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`,
            '_blank'
        );
    };

    const shareToFacebook = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
            )}`,
            '_blank'
        );
    };

    const shareToReddit = () => {
        const title = 'Check out my Politicalc quiz results!'; // You can customize the title for the Reddit post
        window.open(
            `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}`,
            '_blank'
        );
    };


    const shareToDiscord = () => {
        window.open(
            `https://discord.com/channels/@me?content=${encodeURIComponent(shareUrl)}`,
            '_blank'
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ textAlign: 'center', borderRadius: '16px 16px 0 0' }}>
                Share your results
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="body1">
                        See how you compare to your friends.
                    </Typography>
                    <Box mt={2} mb={3}>
                        <Button
                            variant="containedPrimary"
                            startIcon={isMobileShareSupported ? <ShareIcon /> : <ClipboardIcon />}
                            onClick={shareLink}
                        >
                            {isMobileShareSupported ? 'Share Link' : 'Copy Link'}
                        </Button>
                        {!isMobileShareSupported && copySuccess && (
                            <Box mt={1} textAlign="center">
                                <Typography variant="caption" textAlign="center">{copySuccess}</Typography>
                            </Box>
                        )}
                    </Box>
                    <Typography variant="h6" align="center">
                        or share on social media:
                    </Typography>
                    <ShareButtonWrapper mt={2}>
                        <IconButton color="primary" onClick={shareToTwitter}>
                            <TwitterIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={shareToFacebook}>
                            <FacebookIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={shareToReddit}>
                            <RedditIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={shareToDiscord}>
                            <img
                                src="/discord-icon.svg"
                                alt="Discord Icon"
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    fill: 'currentColor',
                                }}
                            />
                        </IconButton>
                    </ShareButtonWrapper>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ShareResultsModal;