import { React, useState } from 'react';
import { Card, CardContent, Typography, Grid, Divider, Button, Box } from '@mui/material';
import { Rating } from '@smastrom/react-rating'
import { styled } from '@mui/system';
import { supabase } from '../supabaseClient';
import ShareIcon from '@mui/icons-material/Share';
import ShareResultsModal from './ShareResultsModal';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledCard = styled(Card)(({ theme }) => ({
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    width: '100%',
    marginBottom: theme.spacing(8),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    margin: theme.spacing(2, 0),
    width: '100%',
}));

const RateAndShare = ({ testId, shareId }) => {
    const [rating, setRating] = useState(0);
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleRate = (newValue) => {
        setRating(newValue);
        saveRating(newValue);
    };
    

    const saveRating = async (rating) => {
        console.log("Attempting to save rating. Test ID: ", testId, "\nRating: ", rating);
        const { data, error } = await supabase
            .from('tests')
            .upsert([{ id: testId, rating: rating }]);
        console.log(data);
        if (error) {
            console.error("Error updating rating: ", error);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <StyledCard>
            <CardContent>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={5}>
                        <Typography variant="h6" align="center">Rate your results</Typography>
                        <Box display="flex" justifyContent="center">

                            <Rating
                                style={{ maxWidth: 180 }}
                                value={rating}
                                onChange={handleRate}
                            />
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} sm={5}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                data-hide-for-download
                                variant="contained"
                                color="primary"
                                startIcon={<ShareIcon />}
                                onClick={handleOpen}
                            >
                                Share Results
                            </Button>
                            <ShareResultsModal open={open} onClose={handleClose} shareId={shareId} />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
};

export default RateAndShare;