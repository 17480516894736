import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Terms & Conditions
      </Typography>
      <Typography paragraph>
        By using our website, you agree to be bound by these Terms & Conditions.
      </Typography>
      <Typography paragraph>
        The content on our website is for general information purposes only and is not intended to provide any advice or recommendations. We reserve the right to modify or update the content at any time without prior notice.
      </Typography>
      <Typography paragraph>
        We do not guarantee the accuracy, completeness, or usefulness of any information provided on our website. Any reliance you place on such information is strictly at your own risk.
      </Typography>
      <Typography paragraph>
        We are not responsible for any loss or damage that may arise from your use of our website or your reliance on any content.
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;