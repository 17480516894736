import React from 'react';
import { Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';
import styled from '@emotion/styled';

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FFA07A'};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 12px 12px 0 0;
  color: white;
  height: 96px;
`;

const GroupingHeadline = styled(Typography)`
  font-size: 2.75rem; // 42px in rem
  font-weight: bold;
`;

const AxisBar = styled(Box)`
  display: flex;
  align-items: center;
`;

const Side = styled(Box)`
  flex-basis: ${({ percentage }) => percentage}%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FF6384'};
  height: 16px;
`;

const AxisLabel = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1)}; // Add padding to separate labels from bars
`;

const TitleText = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Axis = ({
    leftLabel,
    rightLabel,
    leftPercentage,
    rightPercentage,
    leftColor,
    rightColor,
    axis1Text,
    axis2Text
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dominantSide =
        leftPercentage >= rightPercentage
            ? { label: leftLabel, percentage: leftPercentage, color: leftColor }
            : { label: rightLabel, percentage: rightPercentage, color: rightColor };

    return (
        <Box mt={3} paddingLeft="24px" paddingRight="24px">
            <TitleText align="left">
                {Math.round(dominantSide.percentage)}% {dominantSide.label}
            </TitleText>
            <AxisBar>
                <Box width="100%" display="flex">
                    <Side
                        bgColor={leftPercentage >= rightPercentage ? leftColor : '#D9D9D9'}
                        percentage={leftPercentage}
                    />
                    <Side
                        bgColor={leftPercentage < rightPercentage ? rightColor : '#D9D9D9'}
                        percentage={rightPercentage}
                    />
                </Box>
            </AxisBar>
            <Box display="flex" justifyContent="space-between" mt={1}>
                <AxisLabel variant='caption' align="center">
                    {Math.round(leftPercentage)}%{'\n'}
                    {leftLabel.toUpperCase()}
                </AxisLabel>
                <AxisLabel variant='caption' align="center">
                    {Math.round(rightPercentage)}%{'\n'}
                    {rightLabel.toUpperCase()}
                </AxisLabel>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
                <Typography variant='p'>
                    {leftPercentage >= rightPercentage ? axis1Text : axis2Text}
                </Typography>
            </Box>
        </Box>
    );
};


const QuizAxesExplanation = ({ grouping }) => {
    document.body.style.zoom = "90%";
    const getDominantSides = () => {
        return grouping.axes.map((axis) => {
            if (axis.score >= 0.5) {
                return "left";
            } else {
                return "right";
            }
        });
    };
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const dominantSides = getDominantSides();

    if (!grouping) {
        return null;
    }

    const aboutYouText = grouping.text.aboutYou[`${dominantSides[0]}_${dominantSides[1]}`];

    return (
        <Box>
            <StyledCard>
                <Header bgColor={grouping.bgColor}>
                    <Typography variant="h5" component="div">
                        {grouping.name}
                    </Typography>
                </Header>
                <CardContent>
                    {/* Top row */}
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                        {/* Left column */}
                        <Box
                            flex="1"
                            pr={isMobile ? 0 : 2}
                            order={isMobile ? 1 : 0}
                        >
                            {grouping.axes &&
                                grouping.axes.map((axis, idx) => (
                                    <Axis
                                        key={idx}
                                        leftLabel={axis.left}
                                        rightLabel={axis.right}
                                        leftPercentage={axis.score * 100}
                                        rightPercentage={(1 - axis.score) * 100}
                                        leftColor={grouping.bgColor}
                                        rightColor={grouping.bgColor}
                                        axis1Text={grouping.text[`axis${idx + 1}`].left}
                                        axis2Text={grouping.text[`axis${idx + 1}`].right}
                                    />
                                ))}
                        </Box>
                        {/* Right column */}
                        <Box
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            pl={isMobile ? 0 : 2}
                            order={isMobile ? 0 : 1}
                        >
                            <Box
                                width={isMobile ? 200 : 380}
                                height={isMobile ? 200 : 380}
                                component="img"
                                src={grouping.image}
                                alt="Image description"
                                sx={{ borderRadius: 1 }}
                            />
                        </Box>
                    </Box>
                    {/* Bottom row */}
                    <Box mt={3} paddingLeft="24px" paddingRight="24px" paddingBottom="20px">
                        <Typography
                            variant="overline"
                            component="div"
                            sx={{ borderBottom: '1px solid grey', textAlign: 'center' }}
                        >
                            <Box component="span" px={1} bgcolor="white">
                                ABOUT YOU
                            </Box>
                        </Typography>
                        <Box marginTop="16px" sx={{ textAlign: 'left' }}>
                            <Typography variant={isMobile ? "p" : "h6"} sx={{ mt: 1, textAlign: 'left' }}>
                                {aboutYouText}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </StyledCard>
        </Box>
    );
};

export default QuizAxesExplanation;