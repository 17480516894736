import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Card,
    Typography,
    Box,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Button,
    CircularProgress,
    useMediaQuery,
} from '@mui/material';
import ProgressBarWithLabel from './ProgressBarWithLabel';
import { supabase } from '../supabaseClient';
import theme from '../theme';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  min-height: 120px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FFA07A'};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 12px 12px 0 0;
  color: white;
  height: 50px;
`;

function getRandomSamples(array, n) {
    const shuffledArray = [...array];
    shuffleArray(shuffledArray);

    return shuffledArray.slice(0, n);
};

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
};

function generateShareId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const Quiz = () => {
    const [page, setPage] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quizCompletionLoading, setQuizCompletionLoading] = useState(false);
    const [answers, setAnswers] = useState({});
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [unansweredQuestions, setUnansweredQuestions] = useState({});
    const questionRefs = useRef([]);


    const questionsPerPage = 12;
    const numOfPages = Math.ceil(questions.length / questionsPerPage);


    const displayedQuestions = useMemo(() => {
        return questions.slice((page - 1) * questionsPerPage, page * questionsPerPage);
    }, [questions, page, questionsPerPage]);

    const handleAnswerChange = (questionId, value) => {
        const question = questions.find(q => q.id === questionId);
        const adjustedValue = question.direction * value;

        setAnswers({
            ...answers,
            [questionId]: {
                axis: question.axis,
                grouping: question.grouping,
                value: adjustedValue,
                displayValue: value,
                text: question.questionText
            }
        });

        // Find the index of the current question in the displayedQuestions array
        const questionIndex = displayedQuestions.findIndex((q) => q.id === questionId);

        // If there's a next question on the current page, scroll to it
        if (questionIndex < displayedQuestions.length - 1) {
            const nextQuestionRef = questionRefs.current[questionIndex + 1];

            if (nextQuestionRef && nextQuestionRef.current) {
                nextQuestionRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };


    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        try {

            // Fetch axes from the database using a raw SQL query
            const { data: axesData, error: axesError } = await supabase.rpc('get_axes');

            if (axesError) {
                throw axesError;
            }

            const axes = axesData.map((item) => item.axis);

            // Fetch questions from the database
            const { data, error } = await supabase
                .from('questions')
                .select('id, axis, grouping, direction, questionText');

            if (error) {
                throw error;
            }

            const numberOfQuestionsPerAxis = process.env.NODE_ENV === 'production' ? 6 : 1;

            let finalQuestions = [];

            axes.forEach((axis) => {
                const questionsForAxis = data.filter((question) => question.axis === axis);
                const randomSamples = getRandomSamples(questionsForAxis, numberOfQuestionsPerAxis);
                finalQuestions = finalQuestions.concat(randomSamples);
            });

            shuffleArray(finalQuestions);
            setQuestions(finalQuestions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching questions:', error.message);
            setLoading(false);
        }
    }, []);


    // Fetch questions when the component mounts
    useEffect(() => {
        fetchQuestions();
    }, []);

    // Handle the before unload event whenever the answers state changes
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (Object.keys(answers).length > 0) {
                e.preventDefault();
                e.returnValue = 'You will lose progress on this form';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [answers]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const renderQuestions = useMemo(() => {

        /*const startIndex = (page - 1) * questionsPerPage;
        const endIndex = startIndex + questionsPerPage;
        const displayedQuestions = questions.slice(startIndex, endIndex);*/

        // Reset the questionRefs array when the page changes
        questionRefs.current = [];

        if (loading) {
            return (
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </Container>
            );
        }


        return displayedQuestions.map((question, index) => {
            const questionRef = (questionRefs.current[index] = React.createRef());
            return (
                <Grid item xs={12} key={question.id} ref={questionRef}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography
                            variant="h5"
                            textAlign="center"
                            padding="20px 20px"
                            fontWeight="700"
                        >
                            {question.questionText}
                        </Typography>
                        {unansweredQuestions[question.id] && (
                            <Typography
                                variant="h5"
                                color="error"
                                fontWeight="700"
                                marginLeft="4px"
                            >
                                *
                            </Typography>
                        )}
                    </Box>
                    <FormControl component="fieldset" fullWidth="true">
                        <RadioGroup
                            row
                            value={answers[question.id] ? answers[question.id].displayValue : ''}
                            onChange={(e) => handleAnswerChange(question.id, Number(e.target.value))}
                        >
                            <Grid container justifyContent="center" textAlign="center" padding="20px 20px" alignItems="flex-start" style={{ width: '100%', minHeight: '4rem' }}>
                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                                        <FormControlLabel
                                            value="-3"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'darkred' } }}
                                                />
                                            }
                                            label="Disagree"
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">

                                        <FormControlLabel
                                            value="-2"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'darkred' } }}
                                                />
                                            }
                                            label=""
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">

                                        <FormControlLabel
                                            value="-1"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'darkred' } }}
                                                />
                                            }
                                            label=""
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">

                                        <FormControlLabel
                                            value="0"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'gray' } }}
                                                />
                                            }
                                            label={isDesktop ? "Neutral" : ""}
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">

                                        <FormControlLabel
                                            value="1"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'green' } }}
                                                />
                                            }
                                            label=""
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">

                                        <FormControlLabel
                                            value="2"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'green' } }}
                                                />
                                            }
                                            label=""
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1.7} sm={1.7} style={{ width: 'calc(100% / 7)' }} container justifyContent="center" alignItems="center">
                                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                                        <FormControlLabel
                                            value="3"
                                            control={
                                                <Radio
                                                    sx={{ '&.Mui-checked': { color: 'green' } }}
                                                />
                                            }
                                            label="Agree"
                                            labelPlacement="bottom"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            );
        });
    }, [loading, page, questions, displayedQuestions, questionsPerPage, answers, unansweredQuestions]);

    const isPageComplete = () => {
        const startIndex = (page - 1) * questionsPerPage;
        const endIndex = startIndex + questionsPerPage;
        const currentPageQuestions = questions.slice(startIndex, endIndex);
        const currentUnanswered = {};

        currentPageQuestions.forEach((question) => {
            if (!answers[question.id]) {
                currentUnanswered[question.id] = true;
            }
        });

        setUnansweredQuestions(currentUnanswered);
        return Object.keys(currentUnanswered).length === 0;
    };

    const progress = useMemo(() => {
        return ((page - 1) / numOfPages) * 100;
    }, [page, numOfPages]);

    const isQuizComplete = () => {
        return questions.length === Object.keys(answers).length;
    };

    const handleQuizCompletion = async () => {
        setQuizCompletionLoading(true);
        window.scrollTo(0, 0);

        try {
            // Generate a UUID for the session and the test
            const session_id = uuidv4();
            const test_id = uuidv4();
            const share_id = generateShareId();

            // Save the total number of questions answered
            let num_of_questions_answered = 0;

            // Get the current timestamp
            const submitted_at = new Date().toISOString();

            // Iterate through the answers object and insert each response into the responses table
            for (const question_id in answers) {
                const question = answers[question_id];
                const response_id = uuidv4();
                num_of_questions_answered += 1;

                const { error } = await supabase
                    .from('responses')
                    .insert([
                        {
                            id: response_id,
                            session_id,
                            test_id,
                            user_id: null, // Set this to the logged-in user's ID when you have a logged-in state
                            created_at: submitted_at,
                            question_id: parseInt(question_id),
                            question_text: question.text,
                            question_axis: question.axis,
                            question_grouping: question.grouping,
                            environment: process.env.NODE_ENV,
                            question_answer: question.value,
                        },
                    ]);

                if (error) {
                    console.error('Error inserting response:', error);
                }
            }

            navigate('/results', { state: { answers, test_id, session_id, num_of_questions_answered, share_id } });
        } catch (error) {
            console.error('Error handling quiz completion:', error);
        } finally {
            setQuizCompletionLoading(false);
        }
    };

    if (quizCompletionLoading) {
        return (
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </Box>
            </Container>
        );
    };

    return (
        <Container>
            <Box my={4} >
                <Header bgColor="#333333"><Typography variant="h6">Instructions</Typography></Header>
                <StyledCard>
                    <Box component="ul" textAlign="left" style={{ lineHeight: '2rem' }}>
                        <li>
                            <Typography>
                                For each statement, indicate how much you agree or disagree with it.
                            </Typography>
                        </li>
                        <li>
                            <Typography >
                                Some statements are intentionally ambiguous to elicit your instinctive reaction.
                            </Typography>
                        </li>
                        <li>
                            <Typography >
                                Be yourself and answer honestly to get the most out of the test.
                            </Typography>
                        </li>
                    </Box>
                </StyledCard>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {progress > 0 ? <ProgressBarWithLabel value={progress} /> : null}
                </Box>
                <Grid container spacing={2}
                >
                    {renderQuestions}
                </Grid>
                <Box mt={4} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (page < numOfPages && isPageComplete()) {
                                setPage(page + 1);
                            } else if (isQuizComplete()) {
                                handleQuizCompletion();
                            } else {
                                alert('Please answer all questions before proceeding.');
                            }
                        }}
                        disabled={page === numOfPages && !isQuizComplete()}
                    >
                        {page < numOfPages ? 'Continue' : 'Complete Test'}
                    </Button>
                </Box>
                {/*
                {page === numOfPages && (
            <Box width="100%" textAlign="center" mt={2}>
                <Button
                    variant="containedSecondary"
                    size="large"
                    onClick={() => {
                        // Your custom logic for the button
                    }}
                >
                    Give me more!
                </Button>
            </Box>
                )} */}

                <Box alignItems="center" textAlign="center" marginTop="20px">
                    <Typography variant="caption" textAlign="center">
                        {page < numOfPages ? (
                            'Just a bit more...'
                        ) : (
                            <>
                                By submitting the test, you accept our{' '}
                                <RouterLink to="/terms" style={{ textDecoration: 'none' }}>
                                    <Typography component="span" variant="caption" color="primary">
                                        Terms & Conditions
                                    </Typography>
                                </RouterLink>{' '}
                                and{' '}
                                <RouterLink to="/privacy-policy" style={{ textDecoration: 'none' }}>
                                    <Typography component="span" variant="caption" color="primary">
                                        Privacy Policy
                                    </Typography>
                                </RouterLink>
                            </>
                        )}
                    </Typography>
                </Box>
            </Box>
        </Container>

    );
};

export default React.memo(Quiz);