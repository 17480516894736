import { React, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Container, Typography, Chip, Box } from '@mui/material';
import QuizAxesExplanation from './QuizAxesExplanation'
import groupingsConfig from './groupingsConfig';
import modelIdeologies from './ideologyConfig.json';
import SummaryCard from './SummaryCard';
import RateAndShare from './RateAndShare';
import { supabase } from '../supabaseClient';
import { normalizeScore, createGroupingsObject, loadTestDataFromDatabase, calculateAxisScores } from './TestServices';
import { Helmet } from 'react-helmet';


function ShareResults() {

    const location = useLocation();
    const answers = location.state?.answers || [];
    const [axisScores, setAxisScores] = useState(null);
    const [shareId, setShareId] = useState('');


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const retrievedShareId = searchParams.get('shareid');
        setShareId(retrievedShareId);

        const loadResults = async () => {
            if (retrievedShareId) {
                const loadedAxisScores = await loadTestDataFromDatabase(retrievedShareId);
                setAxisScores(loadedAxisScores);
            } else {
                setAxisScores(answers);
            }
        };

        loadResults();
    }, [location.search]);


    let groupings = [];
    let normalizedRadarData = [];

    if (axisScores) {
        groupings = createGroupingsObject(axisScores);

        // Assuming minValue and maxValue are defined
        const minValue = -3.0;
        const maxValue = 3.0;

        // Calculate the midpoint for each axis

        const radarData = Object.entries(axisScores).map(([axis, data]) => ({
            axis,
            score: data.score,
        }));

        const normalizeScores = (value, minValue, maxValue) => {
            const normalizedValue = (value - minValue) / (maxValue - minValue);
            return normalizedValue;
        };

        normalizedRadarData = radarData.map((item) => ({
            ...item,
            complementaryScore: normalizeScores(item.score, minValue, maxValue),
            score: 1 - normalizeScores(item.score, minValue, maxValue),
        }));
    }


    return (
        <div>
            <Helmet>
                <title>Check Out My Politicalc Test Results</title>
                <meta property="og:title" content="Check out my Politicalc Test Results!" />
                <meta property="og:description" content="I just took this political quiz and here are my results. Take the quiz yourself and see how we compare!" />
                <meta property="og:image" content="https://www.politicalc.com/Open_Graph_Share.png" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://www.politicalc.com/Open_Graph_Share.png" />
                <meta name="twitter:image:type" content="image/png" />
                <meta name="twitter:title" content="Check out my Politicalc Test Results!" />
                <meta name="twitter:description" content="I just took this political quiz and here are my results. Take the quiz yourself and see how we compare!"></meta>
            </Helmet>
            {(axisScores) ? (
                <Box textAlign="center" paddingBottom={"40px"}>
                    {/*<Typography variant="h3">The results are in...</Typography>*/}
                    <Container sx={{ marginTop: 4, marginBottom: 4 }}>
                        <SummaryCard groupings={groupings} modelIdeologies={modelIdeologies} radarData={normalizedRadarData} shareId={shareId} />
                    </Container>
                    <Container sx={{ marginTop: 4, marginBottom: 4 }}>
                        {groupings.map((grouping, index) => (
                            <QuizAxesExplanation key={index} grouping={grouping} />
                        ))}
                    </Container>
                </Box>
            ) : (
                <Box textAlign="center" paddingBottom={"40px"}>
                    <Typography variant="h3">Take the test!</Typography>
                </Box>
            )}

        </div>
    );
}

export default ShareResults;