import { createTheme } from '@mui/material/styles';


const baseTheme = createTheme({
  palette: {
    background: {
      default: '#faf9f7'
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
    highlight: {
      main: '#333333',
      dark: '#00b78e',
    },
  },
  typography: {
    fontFamily: '"Cabin", sans-serif',
    h1: {
        fontFamily: '"Playfair Display"',
        '@media (max-width: 299.9px)': {
          fontSize: '3rem'
        },
      },
    h2: {
      fontFamily: '"Playfair Display"',
    },
    h3: {
      fontFamily: '"Playfair Display"',
      '@media (max-width: 299.9px)': {
        fontSize: '2.5rem'
      },
    },
    h5: {
      '@media (max-width: 299.9px)': {
        fontSize: '1.0rem'
      },
    },
    h6: {
      '@media (max-width: 299.9px)': {
        fontSize: '1.0rem'
      },
    },
    li: {
      '@media (max-width: 299.9px)': {
        fontSize: '0.75rem'
      },
    },
    ul: {
      '@media (max-width: 299.9px)': {
        fontSize: '0.75rem'
      },  
    },
    caption: {
      '@media (max-width: 299.9px)': {
        fontSize: '0.5rem'
      },
    }
  },
});

const theme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 32,
        },
        containedPrimary: {
          fontFamily: '"Cabin", sans-serif',
          fontWeight: "700",
          backgroundColor: baseTheme.palette.highlight.main,
          color: '#FFFFFF',
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          '&:hover': {
            backgroundColor: baseTheme.palette.highlight.dark,
          },
          padding: '8px 60px',
          margin: '10px',
          fontSize: '1rem',
          '@media (max-width: 299.9px)': {
            padding: '8px 20px', 
            fontSize: '0.85rem'
          },
        },
        containedSecondary: {
          fontFamily: '"Cabin", sans-serif',
          color: '#333333',
          fontWeight: "700",
          backgroundColor: baseTheme.palette.background.default,
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: baseTheme.palette.primary.dark,
          },
          '@media (max-width: 299.9px)': {
            padding: '8px 20px', 
            fontSize: '0.85rem'
          },
          padding: '8px 60px',
          border: '1px solid #333333'
        },
        tertiary: {
          fontFamily: '"Cabin", sans-serif',
          color: '#000000',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFFFFF'
          },
          '@media (max-width: 299.9px)': {
            padding: '8px 20px', 
            fontSize: '0.85rem'
          },
        },
        navbarPrimary: {
          fontFamily: '"Cabin", sans-serif',
          fontWeight: "700",
          backgroundColor: baseTheme.palette.highlight.main,
          color: '#FFFFFF',
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          '&:hover': {
            backgroundColor: baseTheme.palette.highlight.dark,
          },
          padding: '8px 40px',
          '@media (max-width: 300px)': {
            padding: '8px 20px', 
            fontSize: '0.8rem'
          },
          margin: '10px',
          fontSize: '1rem'
        },
      },

      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: '#333333',
          borderRadius: 16,
          fontSize: '1rem',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        arrow: {
          color: 'white',
        },
      },
    },
  },
  globals: {
    '@global': {
      body: {
        backgroundColor: baseTheme.palette.background.default,
      },
    },
  }
});

export default theme;