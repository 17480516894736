const groupingsConfig = {
    "Society and Individuals": {
      name: "Society and Individuals",
      bgColor: "#0D573D",
      image: "/Society_and_Individuals.svg",
      text: {
        // Conservative - Permissive
        axis1: {
          left: "Conservative individuals favor traditional social values, norms and institutions. They believe in preserving cultural heritage and are cautious of radical changes in social order.",
          right: "Permissive are tolerant, open and value personal freedom and social change. They believe individuals should be free to live as they choose as long as they don't harm others. They are open to revising social norms and cultural values with the spirit of the times.",
        },
        // Communitarian - Individualist
        axis2: {
          left: "Communitarians believe that people are fundamentally part of a community. They are preoccupied with questions of identity, and seek to form strong shared identities.",
          right: "Individualists, as the name suggests, see individuals as autonomous actors. They believe individuals should be free to pursue their own interests and happiness in their own way. They are wary of communal obligations that infringe on personal freedom, and fundamentally believe that some spheres of life should be protected from society.",
        },
        aboutYou: {
          left_left: "You place high value on tradition, community and social cohesion. You believe individuals have a duty to their communities and cultural heritage. We should be wary of social change that erodes our way of life and communities.",
          left_right: "You value tradition and personal freedom, believing that individuals should be free to live as they choose, but should keep their business to themselves. People like you are more likely to believe in strong local government, personal responsibility and have a disdain for central control.",
          right_left: "You place strong value on personal freedom and community. You believe individuals should be free to live as they choose, and form strong communities around their shared identity. People like you are likely to support the rights of historically-oppressed groups and minorities, and support protecting the unique cultures and identities of these groups.",
          right_right: "You are a strong believer in personal freedom and the power of each individual to live their life to the fullest. You tend to have a healthy skepticism towards tradition, appeals to the past, or identity-based arguments. You fundamentally believe that society and the individuals comprising it flourish when people are free to experiment with different lifestyles and choices.",
        },
      },
    },

    "Ownership and Equality": {
        name: "Ownership and Equality",
        bgColor: "#BB7C32",
        image: "/Ownership_and_Equality.svg",
        text: {
            // Privatist - Collectivist
          axis1: {
            left: "Privatists believe in strong private property rights and free markets. They are skeptical of government intervention in the economy and redistributive policies. They believe that ownership is a key part of being human and a source of human freedom and flourishing.",
            right: "Collectivists are skeptical of private property and fundamentally believe that prosperity must be broadly shared. They are more likely to support large-scale government economic programs in the name of equality and social justice, including redistribution, taxation and welfare.",
          },
          // Meritocratic - Egalitarian
          axis2: {
            left: "Meritocratic individuals believe that economic and social rewards should be allocated based primarily on talent, hard work and achievement. Underlying meritocracy is the belief that some competition between individuals is key for creating propserity.",
            right: "Egalitarians believe that there are arbitrary inequalities in opportunity and outcome in society that should be addressed. They support policies aimed at leveling the playing field - promoting equal access to opportunities and equalizing economic and social outcomes, especially for disadvantaged groups.",
          },
          aboutYou: {
            left_left: "You believe in free markets, strong property rights and meritocracy. You think economic outcomes should be determined by talent, hard work and market forces - not government intervention. Policies should aim to equalize opportunities, not outcomes.",
            left_right: "You value both private property rights, economic efficiency and equal outcomes. You generally believe that the free market is a force for the good, but also support policies to raise living standards and promote economic equality. You believe a strong social programs and welfare are important to reign in the inequality from market forces.",
            right_left: "You believe that talent, hard work and dedication are key to ensuring economic progress and prosperity, but that the talented and successful owe a debt to the society that raised them. We should create a system where people can excel, but the fruits of these achievements need to be shared widely. You support government policies aimed at leveling the playing field, both in terms of opportunities and outcomes.",
            right_right: "You believe government intervention in the economy should aim to promote both equal opportunities and outcomes, especially for disadvantaged groups. You're more likely to support policies such as progressive taxation, social welfare and policies explicitly aimed at redistributing wealth and equalizing economic outcomes. Free markets must to be balanced with collective responsibility.",
          },
        },
      },

      "Rules and Outcomes": {
        name: "Rules and Outcomes",
        bgColor: "#07065A",
        image: "/Rules_and_Outcomes.svg",
        text: {
            // Ideological - Pragmatic
          axis1: {
            left: "Ideological individuals have strong and consistent convictions. They hold these principles as part of their core identity, and expect leaders to uphold the same standard. They are suspicious of ideological compromises for short-term gains.",
            right: "Pragmatic individuals value outcomes and results. They believe politics is an arena to improve people's lives, not to score ideological points. They evaluate leaders, policies and institutions based on outcomes and results, and shy away from ideological purity.",
          },
          // Loyalist - Proceduralist
          axis2: {
            left: "Loyalist individuals respect authority, hierarchy and institutions. They believe leaders deserve loyalty and deference if they are to be effective. They believe leaders have a unique role in ensuring stability and order are maintained, are more likely to identify with specific leaders and politicians.",
            right: "Proceduralists believe in the impartial rule of law and proper institutional process. They think no individual or leader, regardless of authority, is above the procedures and rules of institutions. In their eyes, questioning leaders who bend or break rules strengthens institutions, and strong rule-based systems are the main ingredient for prosperity.",
          },
          aboutYou: {
            left_left: "You value principles, consistency and strong leaders. You believe policies and leaders should uphold certain ideological values. Questioning or opposing leaders and institutions that embody these values can undermines moral authority and stability. Citizens owe a level of loyalty to their government and leadership, even if they don't agree with them completely.",
            left_right: "You have a deep respect for principles, integrity and the rule of law. You believe strongly that leaders and the policies they enact should uphold ideological values. You don't like cutting corners when it comes to the proper workings of the law, institutions and ceremonies. Compromising on ideological matters means that you already lost. ",
            right_left: "You take a practical, no-nonsense approach to politics. Leaders should be given trust and the ability to do what they set out to do, and then be judged based on the results. You understand that haggling, compromise and finding shortcuts and loopholes is just a natural part of politics. Politicians should do their job and be evaluated more like business executives.",
            right_right: "You evaluate politics and leaders based on outcomes, evidence and results - not ideological precepts. You dislike purity tests and ideological games. Strong, compentent institutions are what make or break a political system. Individual leaders come and go, and are mostly theater. You believe we need to design strong, capable institutions to drive results, relying more on experts like scientists, economists and judges.",
          },
        },
      },

      "Political Psychology": {
        name: "Political Psychology",
        bgColor: "#552B76",
        image: "/Political_Psychology.svg",
        text: {
            // Suspecting - Trusting
          axis1: {
            left: "Suspecting individuals believe that in politics, there's usually more than meets the eye.  They tend to be more skeptical and cynical towards mainstream opinion, media and institutions. They believe the political establishment does not always have the public's best interests in mind. They think it's important to question dominant narratives and uncover hidden truths.",
            right: "Trusting individuals have more trust in expertise, mainstream opinion and social institutions. They believe media, scientists, NGOs and other institutions are mostly objective and aim to serve the public good. While imperfect, these institutions should be relied upon because we have no better options. Trusting individuals believe they have a good handle on politics, and trust in their abilities to tell truth from fiction.",
          },
          // Emotional - Rational
          axis2: {
            left: "Individuals scoring low on this axis think that emotions, passions and moral arguments have an important role to play in politics. They believe logical reasoning and argumenting alone is insufficient for persuasion and appeals to emotion can be fair and legitimate. They are more likely to hold their political beliefs as core to their personal relationships and wellbeing.",
            right: "Individuals scoring high on this axis believe political opinions and arguments should be based primarily on facts, evidence and logical reasoning rather than passions or prejudices. Appeals to emotion are fallacious, dangerous and hinder productive debate. Political choices should be made objectively and rationally. They keep the world of politics at a healthy distance from their own wellbeing and personal relationships.",
          },
          aboutYou: {
            left_left: "You have a skeptical view of dominant narratives and so-called expertise. Moral arguments and emotional appeals are perfectly valid and actually integral to politics. Elites and mainstream opinion is worthy of skepticism. We need to have hard discussions and discover hard truths to truly drive change.",
            left_right: "You take an evidence-based approach to politics, believing that all claims and opinions, regardless of their popularity, should be questioned and scrutinized based on facts and data - not appeals to emotion or authority. Questioning elites and mainstream opinion is necessary to arrive at objective truths and solutions.",
            right_left: "You believe in relying on expertise, science and data to solve problems but also thinks emotions have a big role to play in politics. Appeals to shared values and human stories can motivate change and bring people together when facts alone are insufficient. While imperfect, mainstream institutions do a good job of serving serve the greater good.",
            right_right: "You believe opinions and policy decisions should be based on evidence, facts and data - not prejudices or appeals to emotion. Expertise and scientific consensus should be relied upon. While imperfect, mainstream institutions aim to serve the public interest through impartial, rational decision making. Emotional appeals only polarize and mislead. Objective, evidence-based debate is the path to truth and progress.",
          },
        },
      },

      "International Relations": {
        name: "International Relations",
        bgColor: "#893544",
        image: "/International_Relations.svg",
        text: {
            // Isolationist - Globalist
          axis1: {
            left: "Isolationists believe that nations should avoid foreign interventions and entanglements. They think military interventions often do more harm than good, and nations should mind their own affairs. They are wary of international organizations infringing on national sovereignty, and in general believe that their country comes first.",
            right: "Globalists believe in an active foreign policy and international cooperation. They support multilateral interventions via alliances and institutions to address humanitarian crises, promote justice and solve global problems. They see globalization and interdependence as inevitable and mutually beneficial.",
          },
          // Hawk - Dove
          axis2: {
            left: "Hawks believe that military intervention and use of force are sometimes necessary to resolve international conflicts and uphold moral values. They think perceived weaknesses like appeasement can provoke aggression from adversaries. Strong security and defense are top priorities, and military force and espionage are valid instruments in the nation's toolkit.",
            right: "Doves believe that diplomacy, cooperation and peaceful conflict resolution are the best approaches to solving international disputes. They are skeptical of military intervention, believing it often exacerbates conflicts and should only be used as an absolute last resort in self-defense. They see overly militaristic policies as counterproductive and antagonistic.",
          },
          aboutYou: {
            left_left: "You believe that our country comes first, and that intervention with other countries' business rarely does any good. You are skeptical of military 'adventures' abroad, believing they often do more harm than good. However, strong security and defense are also top priorities, as perceived weakness provokes aggression. Force may be used preemptively or in self-defense. Alliances are good, but other countries should not meddle in our affairs.",
            left_right: "You believe foreign interventions and use of force almost always end badly, and that we have enough problems at home anyway. Diplomacy and conflict resolution are the paths to international cooperation. Military and national interests alone should not drive foreign policy. Global problems are best solved through multilateral partnerships, not coercion, and each country should focus on solving its own issues first.",
            right_left: "You believe in an active foreign policy, including use of force for humanitarian ends, to promote democracy and uphold international norms. Perceived weakness emboldens rogue actors. Multilateral alliances and intervention are necessary to solve global issues, defend human rights and maintain a stable world order.",
            right_right: "You believes in multilateral cooperation and intervention to solve global problems, promote human rights and uphold international law. However, force should only be used as an absolute last resort in self-defense, as it often worsens conflicts. Diplomacy, openness and democratic values are the paths to peace and should guide foreign policy. Military interests alone should not shape international affairs.",
          },
        },
      },

      "Change and Consensus": {
        name: "Change and Consensus",
        bgColor: "#2E7C7C",
        image: "/Change_and_Consensus.svg",
        text: {
            // Radical - Gradual
          axis1: {
            left: "Radical individuals believe major political and social changes are needed to fix what is fundamentally a broken system. Incremental reforms are insufficient and a mirage. They are more likely to support non-violent civil disobedience and protest as ways to raise awareness and force change.",
            right: "Gradualists believe that political change should happen incrementally, step-by-step, to build consensus and preserve stability. They believe that working within the system using established political processes is the best approach to drive results. Radical changes often have unforeseen consequences and undermine social trust.",
          },
          // Conformist - Contrarian
          axis2: {
            left: "Conformist individuals believe in adhering to social norms and traditions to promote harmony and stability. They align their political views to the mainstream, believing it's a good anchor and that extreme positions are dangerous. Conformist individuals rely on the 'wisdom of the crowd' in politics, and tend to support more centrist, consensus-building agendas.",
            right: "Contrarians value independence of thought, skepticism of popular opinions and challenging social orthodoxies. Non-conformity stimulates needed debates and social progress. Voicing unpopular opinions, even if controversial, strengthens community by fostering open discussion. Silencing dissenting voices undermines intellectual progress, and one shouldn't be ashamed of holding so-called 'extremist' views.",
          },
          aboutYou: {
            left_left: "You believe that major changes are urgently needed to fix a broken system, but that radical changes can threaten stability and may end up creating even worse problems. Protest and civil disobedience can help raise awareness but true change needs to come from ordinary people through established processes and mainstream opinion.",
            left_right: "You are a revolutionary and you love it. A broken system needs radical change to move forward. Protest and challenging orthodoxies and mainstream are necessary tactics to motivate change. Working within a flawed system alone is insufficient to drive the change our society needs. Social change often happens from grassroots movements led by brave people, not institutions and political parties.",
            right_left: "You believe change should happen slowly-but-surely, building consensus to maintain stability and trust in the system. Extremists only threaten our society and pit us against each other, when we need to work together to drive change. You believe that people with extreme political opinions need to 'take a chill pill'. We need to build a strong common ground where all of us move together.",
            right_right: "You believe all voices and opinions need to be heard to build consensus and make progress, even if they challenge prevailing views. However, rapid change also threatens stability and trust. A diversity of thought within established political processes, not radical action outisde it, leads to enduring reform and social cohesion. Non-conformity stimulates debates, but consensus is necessary for change.",
          },
        },
      }


  };
  
  export default groupingsConfig;