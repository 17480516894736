import { React, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Container, Typography, Chip, Box } from '@mui/material';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveBar } from '@nivo/bar';
import QuizAxesExplanation from './QuizAxesExplanation'
import groupingsConfig from './groupingsConfig';
import modelIdeologies from './ideologyConfig.json';
import SummaryCard from './SummaryCard';
import RateAndShare from './RateAndShare';
import { supabase } from '../supabaseClient';
import { normalizeScore, createGroupingsObject, loadTestDataFromDatabase, calculateAxisScores, saveTestDataToDatabase } from './TestServices';


function Results() {
    
    const location = useLocation();
    const answers = location.state?.answers || [];
    const test_id = location.state?.test_id;
    const session_id = location.state?.session_id;
    const num_of_questions_answered = location.state?.num_of_questions_answered;
    const share_id = location.state?.share_id;
    const dataSavedRef = useRef(false);



    // Assuming minValue and maxValue are defined
    const minValue = -3.0;
    const maxValue = 3.0;

    // Calculate the midpoint for each axis
    const midpoint = (minValue + maxValue) / 2;

    const axisScores = calculateAxisScores(answers);
    const groupings = createGroupingsObject(axisScores);

    useEffect(() => {
    
        const saveData = async () => {
          const success = await saveTestDataToDatabase(
            axisScores,
            test_id,
            session_id,
            null,
            share_id,
            num_of_questions_answered
          );
          if (success) {
            dataSavedRef.current = true;
          }
        };
    
        if (!dataSavedRef.current) {
          saveData();
        }
    
        return () => {
          dataSavedRef.current = false;
        };
      }, [axisScores, test_id, session_id, share_id, num_of_questions_answered]);


    const radarData = Object.entries(axisScores).map(([axis, data]) => ({
        axis,
        score: data.score,
    }));

    const normalizeScores = (value, minValue, maxValue) => {
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        return normalizedValue;
    };

    const normalizedRadarData = radarData.map((item) => ({
        ...item,
        complementaryScore: normalizeScores(item.score, minValue, maxValue),
        score: 1 - normalizeScores(item.score, minValue, maxValue),
    }));

    return (
        <div>
            <Box textAlign="center" paddingBottom={"40px"}>
                {/*<Typography variant="h3">The results are in...</Typography>*/}
                <Container sx={{ marginTop: 4, marginBottom: 4 }}>
                    <SummaryCard groupings={groupings} modelIdeologies={modelIdeologies} radarData={normalizedRadarData} shareId={share_id} />
                </Container>
                <Container sx={{ marginTop: 4, marginBottom: 4 }}>
                    {groupings.map((grouping, index) => (
                        <QuizAxesExplanation key={index} grouping={grouping} />
                    ))}
                </Container>
                <Container sx={{ marginTop: 4, marginBottom: 4 }}>
                  <RateAndShare testId={test_id} shareId={share_id}></RateAndShare>
                </Container>
            </Box>
        </div>
    );
}

export default Results;