import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography paragraph>
        This Privacy Policy describes how we collect, use, and protect your information when you visit and interact with our website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Information Collection
      </Typography>
      <Typography paragraph>
        We do not collect any personally identifiable information, such as names or email addresses. However, we do store your test results in our database to enable sharing with others.
      </Typography>
      <Typography paragraph>
        We also use standard analytics tools to collect data about your interactions with our website. This information may include your IP address, browser type, pages visited, and other web usage data.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Use of Information
      </Typography>
      <Typography paragraph>
        We use your test results and survey responses for research purposes and to improve our service. We will never sell your data to third parties.
      </Typography>
      <Typography paragraph>
        We may use aggregated, anonymized data to analyze trends and improve our website's functionality.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Data Protection
      </Typography>
      <Typography paragraph>
        We implement reasonable security measures to protect your information against unauthorized access, disclosure, or destruction. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;