import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, CircularProgress, Grid, Typography, useMediaQuery, Tooltip, useTheme, Autocomplete, TextField, IconButton, InputAdornment } from '@mui/material';
import theme from '../theme';
import styled from '@emotion/styled';
import { Download as DownloadIcon, CopyAll as ClipboardIcon, Send as SendIcon } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import './NewRadarChart.css';
import { normalizeScore, createGroupingsObject, loadTestDataFromDatabase, calculateAxisScores } from './TestServices';


const comparisonOptions = [];

const CustomGridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '&.MuiGrid-item': {
            paddingTop: 0,
            marginTop: 0
        },
    },
    [theme.breakpoints.down('md')]: {
        '&.MuiGrid-item': {
            paddingTop: 0,
            marginTop: 0
        },
    }
}));

const AxisBar = styled(Box)`
  display: flex;
  align-items: center;
`;

const Side = styled(Box)`
  flex-basis: ${({ percentage }) => percentage}%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FF6384'};
  height: 20px;
`;

const AxisLabel = styled(Typography)`
font-weight: 800;  
padding: ${({ theme }) => theme.spacing(0.5)}; // Reduce padding
  color: white; // Set label color to white for better contrast
  
`;

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

const CircleRadarChart = ({ groupings, comparisonGroupings }) => {

    const [hoveredGroupIndex, setHoveredGroupIndex] = useState(-1);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { width, height } = useWindowSize();

    const maxWidth = 500;
    const maxHeight = 500;

    /* const svgWidth = isMobile
        ? Math.min(maxWidth, maxHeight, width - theme.spacing(4), height / 2)
        : Math.min(maxWidth, maxHeight, width * 0.50, height * 0.50);
    const svgHeight = isMobile
        ? Math.min(maxWidth, maxHeight, width - theme.spacing(4), height / 2)
        : Math.min(maxWidth, maxHeight, width * 0.50, height * 0.50);*/


    const svgWidth = isMobile ? maxWidth * 0.5 : maxWidth;
    const svgHeight = isMobile ? maxHeight * 0.5 : maxWidth;

    const centerX = svgWidth / 2;
    const centerY = svgHeight / 2;
    const radius = Math.min(svgWidth, svgHeight) / 2 - 20;

    const degToRad = (degrees) => {
        return (degrees * Math.PI) / 180;
    };

    const angleIncrement = (Math.PI * 2) / (groupings.length * 2);


    const getWedgePath = (index) => {
        const rotationRad = degToRad(-15);
        const angle1 = index * angleIncrement * 2 + rotationRad;
        const angle2 = (index + 1) * angleIncrement * 2 + rotationRad;
        const x1 = centerX + radius * Math.cos(angle1);
        const y1 = centerY + radius * Math.sin(angle1);
        const x2 = centerX + radius * Math.cos(angle2);
        const y2 = centerY + radius * Math.sin(angle2);

        return `M${centerX},${centerY} L${x1},${y1} A${radius},${radius} 0 0,1 ${x2},${y2} Z`;
    };

    const drawTraces = () => {
        const points = [];
        for (let i = 0; i < groupings.length; i++) {
            for (let j = 0; j < groupings[i].axes.length; j++) {
                const angle = (i * 2 + j) * angleIncrement;
                const score = groupings[i].axes[j].score;
                const x = centerX + score * radius * Math.cos(angle);
                const y = centerY + score * radius * Math.sin(angle);
                points.push(`${x},${y}`);
            }
        }
        // Close the polygon by connecting the last point to the first point
        points.push(points[0]);
        return points.join(' ');
    };

    const drawGridLines = () => {
        const gridLines = [];
        for (let i = 0; i < groupings.length * 2; i++) {
            const angle = i * angleIncrement;
            const x = centerX + radius * Math.cos(angle);
            const y = centerY + radius * Math.sin(angle);
            gridLines.push({ x1: centerX, y1: centerY, x2: x, y2: y });
        }
        return gridLines;
    };

    const drawMarkers = () => {
        const markers = [];
        for (let i = 0; i < groupings.length; i++) {
            for (let j = 0; j < groupings[i].axes.length; j++) {
                const angle = (i * 2 + j) * angleIncrement;
                const score = groupings[i].axes[j].score;
                const x = centerX + score * radius * Math.cos(angle);
                const y = centerY + score * radius * Math.sin(angle);
                markers.push({ x, y });
            }
        }
        return markers;
    };

    const getUserLabelPosition = () => {
        const lastPointIndex = groupings.length * 2 - 1;
        const angle = lastPointIndex * angleIncrement;
        const score = groupings[lastPointIndex % 2].axes[1].score;
        const x = centerX + score * radius * Math.cos(angle);
        const y = centerY + score * radius * Math.sin(angle);
        return { x, y };
    };

    const tracePaths = drawTraces();
    const gridLines = drawGridLines();

    const markers = drawMarkers();
    const userLabelPosition = getUserLabelPosition();

    // Handle comparison...

    const drawTracesForComparison = () => {
        if (!comparisonGroupings) return '';
        const points = [];
        for (let i = 0; i < comparisonGroupings.length; i++) {
            for (let j = 0; j < comparisonGroupings[i].axes.length; j++) {
                const angle = (i * 2 + j) * angleIncrement;
                const score = comparisonGroupings[i].axes[j].score;
                const x = centerX + score * radius * Math.cos(angle);
                const y = centerY + score * radius * Math.sin(angle);
                points.push(`${x},${y}`);
            }
        }
        points.push(points[0]);
        return points.join(' ');
    };

    const drawMarkersForComparison = () => {
        if (!comparisonGroupings) return [];
        const markers = [];
        for (let i = 0; i < comparisonGroupings.length; i++) {
            for (let j = 0; j < comparisonGroupings[i].axes.length; j++) {
                const angle = (i * 2 + j) * angleIncrement;
                const score = comparisonGroupings[i].axes[j].score;
                const x = centerX + score * radius * Math.cos(angle);
                const y = centerY + score * radius * Math.sin(angle);
                markers.push({ x, y });
            }
        }
        return markers;
    };

    const getComparisonLabelPosition = () => {
        if (!comparisonGroupings) return null;
        const midPointIndex = comparisonGroupings.length;
        const angle = midPointIndex * angleIncrement;
        const score = comparisonGroupings[midPointIndex % 2].axes[0].score;
        const x = centerX + score * radius * Math.cos(angle);
        const y = centerY + score * radius * Math.sin(angle);
        return { x, y };
    };

    const comparisonTracePaths = drawTracesForComparison();
    const comparisonMarkers = drawMarkersForComparison();
    const comparisonLabelPosition = getComparisonLabelPosition();

    // handle hover...

    const handleMouseOver = (index) => {
        setHoveredGroupIndex(index);
    };

    const handleMouseOut = () => {
        setHoveredGroupIndex(-1);
    };

    const handleTouchStart = (index) => {
        setHoveredGroupIndex(index);
    };

    const handleTouchEnd = () => {
        setHoveredGroupIndex(-1);
    };

    return (
        <Box>
            <svg width={svgWidth} height={svgHeight}>
                {gridLines.map((line, index) => (
                    <line
                        key={index}
                        x1={line.x1}
                        y1={line.y1}
                        x2={line.x2}
                        y2={line.y2}
                        stroke="lightgray"
                        strokeWidth="1"
                    />
                ))}
                {groupings.map((grouping, index) => (
                    <Tooltip
                        key={index}
                        title={
                            <Box>
                                <h3 style={{ paddingLeft: "20px", paddingRight: "20px", color: grouping.bgColor }}>{grouping.name}</h3>
                                <h3 style={{ paddingLeft: "20px", paddingRight: "20px", color: "#333333" }}>You:</h3>
                                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                    {Math.round(grouping.axes[0].score * 100)}% {grouping.axes[0].left},{' '}
                                    {Math.round((1 - grouping.axes[0].score) * 100)}% {grouping.axes[0].right}
                                </div>
                                <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
                                    {Math.round(grouping.axes[1].score * 100)}% {grouping.axes[1].left},{' '}
                                    {Math.round((1 - grouping.axes[1].score) * 100)}% {grouping.axes[1].right}
                                </div>
                                {comparisonGroupings && (
                                    <Box>
                                        <h3 style={{ paddingLeft: "20px", paddingRight: "20px", color: "#333333" }}>Comparison:</h3>
                                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            {Math.round(comparisonGroupings[index].axes[0].score * 100)}% {comparisonGroupings[index].axes[0].left},{' '}
                                            {Math.round((1 - comparisonGroupings[index].axes[0].score) * 100)}% {comparisonGroupings[index].axes[0].right}
                                        </div>
                                        <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
                                            {Math.round(comparisonGroupings[index].axes[1].score * 100)}% {comparisonGroupings[index].axes[1].left},{' '}
                                            {Math.round((1 - comparisonGroupings[index].axes[1].score) * 100)}% {comparisonGroupings[index].axes[1].right}
                                        </div>
                                    </Box>
                                )}
                            </Box>
                        }
                        arrow
                        enterTouchDelay={20} // Shorter delay before the Tooltip appears
                        leaveTouchDelay={3000} // Longer delay before the Tooltip disappears
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: 'white',
                                borderRadius: 16,
                                boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.1)',
                            },
                            '& .MuiTooltip-arrow': {
                                color: 'white',
                            },
                        }}
                    >
                        <path
                            d={getWedgePath(index)}
                            fill={grouping.bgColor}
                            fillOpacity={hoveredGroupIndex === index ? 0.8 : 0.4}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={() => handleMouseOut()}
                            onTouchStart={() => handleTouchStart(index)}
                            onTouchEnd={() => handleTouchEnd()}
                        />
                    </Tooltip>
                ))}
                <polygon
                    points={tracePaths}
                    stroke="#333333"
                    strokeWidth="3"
                    fill="none"
                />
                {markers.map((marker, index) => (
                    <circle
                        key={index}
                        cx={marker.x}
                        cy={marker.y}
                        r={5}
                        fill="#333333"
                    />
                ))}
                <rect
                    x={userLabelPosition.x}
                    y={userLabelPosition.y - 40}
                    width={30}
                    height={16}
                    rx={7.5}
                    ry={7.5}
                    fill="white"
                />
                <text
                    x={userLabelPosition.x + 15}
                    y={userLabelPosition.y - 28}
                    textAnchor="middle"
                    fontSize="10"
                    fontWeight="bold"
                    fill="#333333"
                >
                    You
                </text>
                <polygon
                    points={comparisonTracePaths}
                    stroke="#00b78e"
                    strokeWidth="3"
                    fill="none"
                />
                {comparisonMarkers.map((marker, index) => (
                    <circle
                        key={index}
                        cx={marker.x}
                        cy={marker.y}
                        r={5}
                        fill="#00b78e"
                    />
                ))}
                {comparisonLabelPosition && (
                    <>
                        <rect
                            x={comparisonLabelPosition.x - 48}
                            y={comparisonLabelPosition.y - 40}
                            width={70}
                            height={16}
                            rx={7.5}
                            ry={7.5}
                            fill="white"
                        />
                        <text
                            x={comparisonLabelPosition.x - 10}
                            y={comparisonLabelPosition.y - 28}
                            textAnchor="middle"
                            fontSize="10"
                            fontWeight="bold"
                            fill="#00b78e"
                        >
                            Comparison
                        </text>
                    </>
                )}
            </svg>
        </Box>
    );
};

const Axis = ({
    leftLabel,
    rightLabel,
    leftPercentage,
    rightPercentage,
    leftColor,
    rightColor,
}) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    const isLeftDominant = leftPercentage >= rightPercentage;

    return (
        <Box mt={2}>
            <AxisBar>
                <Box width="100%" display="flex">
                    <Side
                        bgColor={isLeftDominant ? leftColor : "#D9D9D9"}
                        percentage={leftPercentage}
                    >
                        {isLeftDominant && (
                            <AxisLabel className="axis-label" variant="caption" align="center">
                                {Math.round(leftPercentage)}%{"\n"}
                                {leftLabel.toUpperCase()}
                            </AxisLabel>
                        )}
                    </Side>
                    <Side
                        bgColor={!isLeftDominant ? rightColor : "#D9D9D9"}
                        percentage={rightPercentage}
                    >
                        {!isLeftDominant && (
                            <AxisLabel className="axis-label" variant="caption" align="center">
                                {Math.round(rightPercentage)}%{"\n"}
                                {rightLabel.toUpperCase()}
                            </AxisLabel>
                        )}
                    </Side>
                </Box>
            </AxisBar>
        </Box>
    );
};


const NewRadarChart = ({ groupings, summaryCardRef, shareId }) => {

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [comparisonAxisScores, setComparisonAxisScores] = useState(null);
    const [comparisonGroupings, setComparisonGroupings] = useState(null);
    const [comparisonValue, setComparisonValue] = useState(null);

    let isButtonClicked = false;

    const componentRef = useRef();

    const validateInput = (input) => {
        // Check if the input is exactly 6 characters and consists of letters and numbers
        return /^[a-zA-Z0-9]{6}$/.test(input);
    };

    const handleAutocompleteSubmit = async (inputValue) => {
        if (validateInput(inputValue)) {
            setLoading(true);
            setInputError(false);

            const loadedAxisScores = await loadTestDataFromDatabase(inputValue);

            setLoading(false);

            if (loadedAxisScores) {
                const loadedGrouping = createGroupingsObject(loadedAxisScores);
                setComparisonGroupings(loadedGrouping);
                return true; // Return true to indicate successful data loading
            }
        }

        return false; // Return false if validation failed or data loading was unsuccessful
    };

    const handleIconClick = async () => {
        const success = await handleAutocompleteSubmit(comparisonValue);
        setInputError(!success);
    }

    const copyShareId = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareId).then(() => {
                // setCopySuccess('Link copied!');
            });
        } else {
            const textarea = document.createElement("textarea");
            textarea.value = shareId;
            textarea.style.position = "fixed"; // Prevent scrolling to the textarea
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                // setCopySuccess('Link copied!');
            } catch (err) {
                console.error("Fallback: Unable to copy", err);
            }
            document.body.removeChild(textarea);
        }
    };


    return (
        <Container maxWidth="lg" ref={componentRef} sx={{
            paddingLeft: { xs: '0px', sm: '16px' },
            paddingRight: { xs: '0px', sm: '16px' },
        }}>
            <Typography variant="h4">Your Politicalc results: <strong>{shareId}</strong> <IconButton color="primary" onClick={copyShareId}>
                <ClipboardIcon data-hide-for-download />
            </IconButton></Typography>

            <br></br>
            <Typography data-hide-for-download variant="h6" >Get your in-depth analysis below.</Typography>
            <br></br>
            <Grid container spacing={6} alignItems="center" paddingTop="24px" paddingBottom="40px">
                <Grid item xs={12} md={6}>
                    <Box style={{ width: "100%", position: "relative" }}>
                        <CircleRadarChart groupings={groupings} comparisonGroupings={comparisonGroupings} />
                    </Box>
                </Grid>
                <CustomGridItem item xs={12} md={6}>
                    <Box display="flex" justifyContent="content">
                        <Autocomplete
                            data-hide-for-download
                            freeSolo
                            fullWidth
                            value={comparisonValue}
                            onInputChange={(event, newInputValue) => {
                                setComparisonValue(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                                if (event.type === 'change' || (event.type === 'keydown' && event.key === 'Enter')) {
                                    handleAutocompleteSubmit(newValue);
                                }
                            }}
                            options={comparisonOptions}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type code (e.g. A1B2C3) to compare"
                                    variant="outlined"
                                    fullWidth
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // prevent the default action to avoid form submission
                                            handleAutocompleteSubmit(comparisonValue); // add this line
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress size={20} /> : null}
                                                <IconButton
                                                    type="button"
                                                    onClick={async () => {
                                                        await handleAutocompleteSubmit(comparisonValue);
                                                    }}
                                                >
                                                    <SendIcon style={{ color: '#333333' }} />
                                                </IconButton>
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    error={inputError}
                                    helperText={inputError ? <Typography color="error">Please enter a valid 6-character code.</Typography> : null}
                                />
                            )}
                        />
                    </Box>

                    {groupings.map((group, groupIdx) => {
                        return (
                            <div key={groupIdx}>
                                {(group.axes || []).map((axis, idx) => {
                                    return (
                                        <Axis
                                            key={idx}
                                            leftLabel={axis.left}
                                            rightLabel={axis.right}
                                            leftPercentage={axis.score * 100}
                                            rightPercentage={(1 - axis.score) * 100}
                                            leftColor={group.bgColor}
                                            rightColor={group.bgColor}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </CustomGridItem>
            </Grid>
        </Container>
    );
};

export default NewRadarChart;