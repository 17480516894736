import React, { useState, useRef } from 'react';
import { Button, Card, CardContent, Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled, keyframes } from '@mui/system';
import { Download as DownloadIcon } from '@mui/icons-material';
import NewRadarChart from './NewRadarChart';
import ShareIcon from '@mui/icons-material/Share';
import ShareResultsModal from './ShareResultsModal';
import {
    ArrowDownwardSharp
} from '@mui/icons-material';
import html2canvas from 'html2canvas';
import './NewRadarChart.css';

const StyledCard = styled(Card)(({ theme, isMobile }) => ({
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: isMobile ? '12px' : '12px',
    width: '100%',
    marginBottom: theme.spacing(8),
    padding: isMobile ? '0px' : '',
}));


const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FFA07A'};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 12px 12px 0 0;
  color: white;
  height: 96px;
`;

const TitleText = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const AnimatedArrow = styled(ArrowDownwardSharp)`
  animation: ${bounce} 2s infinite;
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
`;

const SummaryCard = ({ groupings, modelIdeologies, radarData, shareId }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const summaryCardRef = useRef();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const downloadComponentAsImage = async () => {
        const hiddenElementsInDownload = document.querySelectorAll('[data-hide-for-download]');
        
        hiddenElementsInDownload.forEach((element) => {
          element.style.display = 'none';
        });
      
        // Use html2canvas to generate the image.
        const canvas = await html2canvas(summaryCardRef.current);
      
        hiddenElementsInDownload.forEach((element) => {
          element.style.display = '';
        });
      
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'politicalc-summary.png';
        link.click();
      };

    const calculateVotingTendencies = () => {
        let totalScore = 0;
        let totalAxes = 0;

        for (const groupName in groupings) {
            groupings[groupName].axes.forEach((axis) => {
                totalScore += axis.score;
                totalAxes++;
            });
        }

        const averageScore = 1 - (totalScore / totalAxes);
        if (averageScore <= 0.33) return 'strongly right-wing';
        if (averageScore <= 0.475) return 'right-wing';
        if (averageScore <= 0.525) return 'centrist';
        if (averageScore <= 0.67) return 'left-wing';
        return 'strongly left-wing';
    };

    const calculateTopMatches = () => {
        const calculateDistance = (userAxes, modelAxisScores) => {
            let distance = 0;
            if (userAxes && modelAxisScores) {
                userAxes.forEach((userAxis) => {
                    const modelAxis = modelAxisScores.find(
                        (axis) => axis.left === userAxis.left && axis.right === userAxis.right
                    );

                    if (modelAxis) {
                        const axisDistance = Math.abs(userAxis.score - modelAxis.score);
                        distance += axisDistance;
                    }
                });
            }
            return distance;
        };

        const matchScores = [];

        if (groupings && modelIdeologies) {
            for (const ideologyName in modelIdeologies) {
                const ideology = modelIdeologies[ideologyName];
                let totalDistance = 0;

                if (ideology && Array.isArray(ideology.scores)) {
                    ideology.scores.forEach((ideologyScoreGroup) => {
                        const userGroup = Object.values(groupings).find(group => group.name === ideologyScoreGroup.groupName);

                        if (userGroup && ideologyScoreGroup.groupName != 'Political Psychology') {
                            totalDistance += calculateDistance(userGroup.axes, ideologyScoreGroup.axisScores);
                        }
                    });
                }

                matchScores.push({
                    name: ideologyName,
                    image: modelIdeologies[ideologyName].imageUrl,
                    distance: totalDistance,
                    percentage: (totalDistance * 100) / 10, // assuming 10 axes without Political Psychology
                });
            }
        }

        const sortedMatchScores = matchScores.sort((a, b) => b.percentage - a.percentage);
        const top3Matches = sortedMatchScores.slice(0, 3).map((match) => `${match.name} (${match.percentage.toFixed(0)}%)`);
        const topMatch = sortedMatchScores[0];

        return {
            top3Matches,
            topMatch
        };
    };

    const calculateTopAxes = () => {
        const axes = [];

        for (const groupName in groupings) {
            groupings[groupName].axes.forEach((axis) => {
                const isLeftSide = axis.score < 0.5;
                const sideName = isLeftSide ? axis.right : axis.left;
                const sideScore = isLeftSide ? 1 - axis.score : axis.score;
                const percentageScore = Math.round(sideScore * 100);

                axes.push({
                    name: sideName,
                    score: axis.score,
                    distanceFromExtreme: Math.min(axis.score, 1 - axis.score),
                    percentageScore: percentageScore,
                });
            });
        }

        axes.sort((a, b) => a.distanceFromExtreme - b.distanceFromExtreme);
        return axes.slice(0, 3).map((axis) => `${axis.name} (${axis.percentageScore}%)`);
    };

    const votingTendencies = calculateVotingTendencies();
    const { top3Matches: topMatches, topMatch } = calculateTopMatches();
    const topAxes = calculateTopAxes();

    return (
        <div ref={summaryCardRef} sx={{ margin: 0, padding: 0 }}>
            <StyledCard isMobile={isMobile}>
                <Header bgColor="#333333">
                    <Typography variant="h5" component="div">
                        Your Results
                    </Typography>
                </Header>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                        paddingTop={{ xs: '0px', sm: '0px' }}
                        direction="column"
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="center">
                                        <img
                                            src={topMatch.image}
                                            alt={topMatch.name}
                                            style={{ maxWidth: '70%' }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TitleText>
                                        About You
                                    </TitleText>
                                    <Box component="ul" textAlign="left" style={{ fontSize: '1.25rem', lineHeight: '2rem' }}>
                                        <li>
                                            <Typography style={{ fontSize: '1.25rem' }}>
                                                Based on your results, we project that your voting tendencies are <strong>{votingTendencies}</strong>.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography style={{ fontSize: '1.25rem' }}>
                                                Your nearest ideological personas are <strong>{topMatches.join(', ')}</strong>
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography style={{ fontSize: '1.25rem' }}>
                                                Your most dominant attributes are: <strong>{topAxes.join(', ')}</strong>
                                            </Typography>
                                        </li>
                                    </Box>
                                    <Button
                                        data-hide-for-download
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ShareIcon />}
                                        onClick={handleOpen}
                                    >
                                        Share Results
                                    </Button>
                                    <ShareResultsModal open={open} onClose={handleClose} shareId={shareId} />
                                    <Button
                                        data-hide-for-download
                                        variant="containedSecondary"
                                        startIcon={<DownloadIcon />}
                                        onClick={downloadComponentAsImage}
                                    >
                                        Save as Image
                                    </Button>
                                </Grid>
                            </Grid>
                            <Box
                                data-hide-for-download
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                                justifyContent="center"
                                sx={{
                                    paddingTop: { xs: '32px', sm: '32px' }, // Add top padding on mobile (xs) and reset on larger screens (sm and up)
                                }}
                            >
                                <AnimatedArrow size="small" />
                                <Typography variant="p" marginLeft="8px" marginRight="8px">
                                    Continue to read more insights
                                </Typography>
                                <AnimatedArrow size="small" />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={3} >
                                <Typography
                                    variant="overline"
                                    component="div"
                                    sx={{ borderBottom: '1px solid grey', textAlign: 'center' }}
                                >

                                </Typography>
                            </Box>
                            {/*
                        <Box height="500px" alignContent="center">
                            <QuizRadarChart radarData={radarData} />
    </Box>*/}
                            <Box alignContent="center" marginTop="40px">
                                <NewRadarChart groupings={groupings} summaryCardRef={summaryCardRef} shareId={shareId} />
                            </Box>
                            <Box   data-hide-for-download display="flex" alignItems="center" textAlign="center" justifyContent="center">
                                <AnimatedArrow size="small" />
                                <Typography variant='p' marginLeft="8px" marginRight="8px">Continue to read more insights</Typography>
                                <AnimatedArrow size="small" />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </StyledCard>

        </div>
    );
};

export default SummaryCard;