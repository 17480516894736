import { React, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import groupingsConfig from './groupingsConfig';
import modelIdeologies from './ideologyConfig.json';
import { supabase } from '../supabaseClient';


export function normalizeScore(score, min = -3, max = 3) {
    return 1 - (score - min) / (max - min);
}

export const loadTestDataFromDatabase = async (share_id) => {
    try {
        const { data, error } = await supabase
            .from('tests')
            .select('*')
            .eq('share_id', share_id)
            .limit(1);

        if (error) {
            console.error('Error loading test data from the database:', error);
            return null;
        }

        const axisMapping = {
            "privatist-collectivist": ["Privatist - Collectivist", "Ownership and Equality"],
            "meritocratic-egalitarian": ["Meritocratic - Egalitarian", "Ownership and Equality"],
            "conservative-permissive": ["Conservative - Permissive", "Society and Individuals"],
            "communitarian-individualist": ["Communitarian - Individualist", "Society and Individuals"],
            "suspecting-trusting": ["Suspecting - Trusting", "Political Psychology"],
            "emotional-rational": ["Emotional - Rational", "Political Psychology"],
            "ideological-pragmatic": ["Ideological - Pragmatic", "Rules and Outcomes"],
            "loyalist-proceduralist": ["Loyalist - Proceduralist", "Rules and Outcomes"],
            "isolationist-globalist": ["Isolationist - Globalist", "International Relations"],
            "hawk-dove": ["Hawk - Dove", "International Relations"],
            "radical-gradual": ["Radical - Gradual", "Change and Consensus"],
            "conformist-contrarian": ["Conformist - Contrarian", "Change and Consensus"]
        };

        const axisScores = {};
        const rowData = data[0]; // Grab the first row of data from the response

        for (const [key, value] of Object.entries(rowData)) {
            if (axisMapping.hasOwnProperty(key)) {
                const [axisName, grouping] = axisMapping[key];
                axisScores[axisName] = { score: value, grouping: grouping };
            }
        }

        return axisScores;

    } catch (error) {
        console.error('Error loading test data from the database:', error);
        return null;
    }
};

export function calculateAxisScores(answers) {
    // Initialize an object to store the scores and counts for each axis
    const axisData = {};

    // Iterate through the answers object
    Object.values(answers).forEach(({ axis, grouping, value }) => {
        // If the axis is not in axisData, initialize the score and count
        if (!axisData[axis]) {
            axisData[axis] = {
                score: 0,
                count: 0,
                grouping: ""
            };
        }

        // Update the score and count for the axis
        axisData[axis].score += value;
        axisData[axis].count += 1;
        axisData[axis].grouping = grouping;
    });

    // Calculate the average score for each axis and store the result in an object
    const axisScores = {};
    Object.entries(axisData).forEach(([axis, data]) => {
        axisScores[axis] = {
            score: data.score / data.count,
            grouping: data.grouping
        };
    });

    return axisScores;
}

export function createGroupingsObject(axisScores) {
    const groupings = {};

    if (axisScores !== null && typeof axisScores !== 'undefined') {

        Object.entries(axisScores).forEach(([axis, data]) => {
            const { grouping, score } = data;

            // Retrieve the default configuration for the current grouping
            const defaultConfig = groupingsConfig[grouping];

            // Check if a default configuration exists for the current grouping
            if (!defaultConfig) {
                console.warn(`No default configuration found for grouping "${grouping}". Skipping...`);
                return; // Skip the current grouping
            }

            const groupingName = defaultConfig.name;

            if (!groupings[groupingName]) {
                groupings[groupingName] = {
                    name: groupingName,
                    description: `Explanation of ${grouping} and its axes.`,
                    axes: [],
                    bgColor: defaultConfig.bgColor,
                    image: defaultConfig.image,
                    text: defaultConfig.text,
                };
            }

            const [left, right] = axis.split(" - ");
            const leftColor = '#00b78e';
            const rightColor = '#00d6a8';
            const normalizedScore = normalizeScore(score);
            groupings[groupingName].axes.push({ left, right, score: normalizedScore, leftColor, rightColor });
        });

        const groupingsArray = Object.values(groupings);

        // Convert the array back into an object using the 'name' property as the key
        const groupingsObject = groupingsArray.reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
        }, {});

        return Object.values(groupings);
    } else {
        console.error("axisScores is null or undefined");
    }
}


export function getAxisLabels(axis) {
    const [axisLeftEdge, axisRightEdge] = axis.split(' - ');
    return { axisLeftEdge, axisRightEdge };
}


export const saveTestDataToDatabase = async (
    axisScores,
    test_id,
    session_id,
    user_id,
    share_id,
    num_questions_answered
) => {
    // Convert axis scores to the appropriate format for the database
    const formattedAxisScores = Object.entries(axisScores).reduce(
        (acc, [axis, data]) => {
            const key = axis.toLowerCase().replace(/ /g, '');
            acc[key] = data.score;
            return acc;
        },
        {}
    );

    // Prepare data to be inserted into the database
    const data = {
        id: test_id,
        created_at: new Date().toISOString(), // Current timestamp
        session_id: session_id,
        environment: process.env.NODE_ENV,
        user_id: user_id,
        ...formattedAxisScores,
        share_id: share_id,
        num_questions_answered: num_questions_answered
    };

    try {
        // Insert the data into the 'tests' table
        const { error } = await supabase.from('tests').insert([data]);

        if (error) {
            console.error('Error saving test data to the database:', error);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error saving test data to the database:', error);
        return false;
    }
};
