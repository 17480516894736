import React, { useCallback, memo } from 'react';
import ReactDOM from 'react-dom/client';
import {
  AppBar,
  Card,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Paper,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes, Redirect, Link, useLocation } from 'react-router-dom';
import Quiz from './Components/Quiz';
import Results from './Components/Results';
import Image from './Hero_image.svg';
import styled from '@emotion/styled';
import ShareResults from './Components/ShareResults';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import '@smastrom/react-rating/style.css'


const MemoizedHome = React.memo(Home);
const MemoizedQuiz = React.memo(Quiz);
const MemoizedResults = React.memo(Results);

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  min-height: 120px;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#FFA07A'};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 12px 12px 0 0;
  color: white;
  height: 40px;
`;


const CenteredImageBox = ({ isMobile }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        order: isMobile ? 1 : 0,
      }}
    >
      <img
        src={Image}
        alt="Hero Illustration"
        style={{ height: isMobile ? '240px' : '320px' }}
      />
    </Box>
  );
};

const sections = [
  {
    title: 'Beyond Left and Right',
    description:
      'Our 12-axis test measures your political attitudes deeply to analyze how you process political concepts and information. Gain insight into your psychology and how it shapes your political opinions.',
    imageUrl: '/radar_chart.png',
  },
  {
    title: 'Get a Detailed Breakdown',
    description:
      'Complete the test to receive a detailed breakdown of your political attitudes. Share it with your friends to see how you compare.',
    imageUrl: '/breakdown.png',
  }
];

function Home() {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container>
      <Box
          sx={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: isMobile ? "column" : "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box sx={{ order: isMobile ? 4 : "unset" }}>
            <CenteredImageBox isMobile={isMobile} />
          </Box>
          <Box sx={{ order: isMobile ? 1 : "unset" }}>
            <Typography variant="h3" component="h3" gutterBottom>
              A new kind of political quiz
            </Typography>
          </Box>
          <Box sx={{ order: isMobile ? 2 : "unset" }}>
            <Typography variant="h5" component="h5" gutterBottom>
              Go beyond left and right. Take this 10 minute test and delve into your
              political personality.
            </Typography>
          </Box>
          <Box sx={{ order: isMobile ? 3 : "unset" }}>
            <Button
              component={Link}
              to="/test"
              variant="contained"
              size="large"
              sx={{
                mt: 2,
              }}
            >
              Take the Test
            </Button>
          </Box>
        </Box>

        <Container sx={{ marginTop: 4, marginBottom: 4 }}>
          {sections.map((section, index) => (
            <Grid
              container
              spacing={4}
              key={index}
              sx={{
                marginBottom: 4,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {index % 2 === 0 ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h2" gutterBottom align="center">
                      {section.title}
                    </Typography>
                    <Typography variant="h6" align="center">
                      {section.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <img
                      src={section.imageUrl}
                      alt={section.title}
                      width={isMobile ? '100%' : '320px'}
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                    <img
                      src={section.imageUrl}
                      alt={section.title}
                      width="100%"
                      max-height="400px"
                      style={{ maxWidth: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                    <Typography variant="h4" component="h2" gutterBottom align="center">
                      {section.title}
                    </Typography>
                    <Typography variant="h6" align="center">
                      {section.description}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Container>
        <Typography variant="h3" component="h3" gutterBottom textAlign="center">
          Delve deep into your political attitudes
        </Typography>
        <Grid container sx={{ marginTop: 4, marginBottom: 4 }} spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#0D573D' fontSize='1.2rem'>Society and Individuals</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                What's a balanced interplay between society, individuals, community and tradition?
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#BB7C32' fontSize='1.2rem'>Ownership and Equality</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                What's the right way to allocate resources and wealth in the economy?
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#07065A' fontSize='1.2rem'>Rules and Outcomes</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                What roles does ideology and individual leaders have in driving progress vs. institutions and rules?
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#552B76' fontSize='1.2rem'>Political Psychology</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                Can we trust what we see in politics? How do we relate to mainstream political opinion?
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#2E7C7C' fontSize='1.2rem'>Change and Consensus</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                What's the best way to drive political change? Do we need to work through the system or drive revolutionary change?
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Header bgColor='#893544' fontSize='1.2rem'>International Relations</Header>
            <StyledCard sx={{ padding: 2 }} elevation={3}>
              <Typography>
                What should be our relationship with other countries and cultures? What's the role of military force vs. diplomacy?
              </Typography>
            </StyledCard>
          </Grid>

        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: '60px'
          }}
        >
          <Button
            component={Link}
            to="/test"
            variant="containedPrimary"
            size="large"
            sx={{ mt: 2 }}
          >
            Take the Test
          </Button>
        </Box>
      </Container>

      <footer sx={{ marginTop: 4, padding: 2, backgroundColor: (theme) => theme.palette.grey[200] }}>
        <Typography variant="body1" align="center">
          &copy; {new Date().getFullYear()} Politicalc. All rights reserved.
        </Typography>
      </footer>
    </>
  );
}

function App() {

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);

  const handleMobileMenuOpen = useCallback((event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  }, []);
  
  const handleMobileMenuClose = useCallback(() => {
    setMobileMenuAnchorEl(null);
  }, []);

  const handleContactUs = () => {
    window.location.href = 'mailto:contact@politicalc.com';
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  
  console.log("Parent component rendered");

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static" color="secondary" sx={{ marginBottom: 4 }}>
          <Toolbar>
            <Typography variant="h6" component="div" flexGrow={1}>
              <Box display="flex" alignItems="center">
                <Link to="/">
                  <img src={isMobile ? './Politicalc_Face_Logo.svg' : './Logo_Horizontal.svg'} alt="Logo" style={{ height: '50px' }}></img>
                </Link>
              </Box>
            </Typography>
            <Hidden smDown>
              {/* <Button variant="tertiary">About the Test</Button>*/}
              <Button variant="tertiary" onClick={handleContactUs} >Contact</Button>
              {/*<Button variant="tertiary">Login</Button>*/}
            </Hidden>
            <Button variant="navbarPrimary" component={Link}
              to="/test">Take the Test</Button>
            <Hidden mdUp>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                aria-controls="mobile-menu"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="mobile-menu"
                anchorEl={mobileMenuAnchorEl}
                keepMounted
                open={Boolean(mobileMenuAnchorEl)}
                onClose={handleMobileMenuClose}
              >
                <MenuItem onClick={handleContactUs}>Contact</MenuItem>
              </Menu>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={<MemoizedHome />} />
          <Route path="/test" element={<MemoizedQuiz />} />
          <Route path="/results" element={<MemoizedResults />} />
          <Route path="/share-results" element={<ShareResults />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;